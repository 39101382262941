body {
  margin: 0;
  padding: 0;
  background-color: #222222;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  color: #ffffff;
}
a {
  color: #84aac2;
  text-decoration: none;
}
a:hover {
  color: #ffad27;
}
#title {
  position: absolute;
  top: 0;
  padding: 0.4em;
  font-weight: bold;
  font-size: 1.15em;
}
#loading {
  display: none;
}

#album-view {
  position: absolute;
  top: 2.5em;
  padding: 1em;
}
#thumbs {
  clear: both;
  line-height: 0;
}

#thumbs img {
  border: 0;
  margin: 0;
  padding: 0;
}
.current-thumb {
  border-top: 1px solid #ffad27 !important;
}
#subalbums {
  padding-top: 1.5em;
}
.album-button {
  float: left;
  display: block;
  width: 150px;
  height: 60px;
  text-align: center;
  font-size: 12px;
  background-repeat: no-repeat;
  background-position: top;
  padding-top: 150px;
  background-image: url(/assets/loading.gif);
}

#next,
#back {
  position: absolute;
  width: auto;
  font-size: 4.5em;
  line-height: 0;
  top: 40%;
  font-weight: bold;
  opacity: 0.35;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=35)";
  filter: alpha(opacity=35);
}
#back {
  left: 0.1em;
}
#next {
  right: 0.1em;
}
#photo {
  border: 0;
  left: 0;
}
#photo-view {
  position: absolute;
  bottom: 150px;
  top: 1.75em;
  overflow: hidden;
  margin-bottom: 0.5em;
  background-color: #111111;
  left: 0;
  right: 0;
  text-align: center;
}
#photo-box {
  display: inline;
}
#photo-links {
  background-color: #000000;
  font-weight: bold;
  height: 10px;
  font-size: 10px;
  line-height: 7px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-right: 10px;
  padding-left: 10px;
  display: none;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  -moz-border-top-right-radius: 5px;
  -moz-border-top-left-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  -webkit-border-top-left-radius: 5px;
}
#photo-links a {
  cursor: pointer;
}
#metadata {
  background-color: #000000;
  width: 340px;
  font-size: 12px;
  line-height: 12px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-right: 10px;
  padding-left: 10px;
  display: none;
  margin: 0 auto;
  margin-top: 1px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  -moz-border-top-right-radius: 5px;
  -moz-border-top-left-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  -webkit-border-top-left-radius: 5px;
  opacity: 0.5;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  filter: alpha(opacity=50);
  overflow: auto;
}
#metadata table {
  margin: auto auto;
  text-align: left;
}
#metadata tr {
  height: 14px;
}
#photo-bar {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
}
#fullscreen,
#fullscreen-divider {
  display: none;
}
.photo-view-container {
  position: absolute;
  height: 150px;
  width: 100%;
  bottom: 0;
  top: auto !important;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  padding: 0 !important;
  text-align: center;
}

#powered-by {
  clear: both;
  text-align: center;
  font-size: 0.85em;
  font-weight: bold;
  text-shadow: rgba(0, 0, 0, 0.5) -1px 0, rgba(0, 0, 0, 0.3) 0 -1px,
    rgba(255, 255, 255, 0.5) 0 1px, rgba(0, 0, 0, 0.3) -1px -2px;
  color: #ffad27;
}
#error-overlay,
#error-text,
#auth-text {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
}
#error-overlay {
  background-color: #000000;
}
#error-text,
#auth-text {
  position: fixed;
  padding-top: 20%;
  text-align: center;
}
#error-text {
  font-size: 4em;
  font-weight: bold;
}
#auth-text input {
  color: rgb(0, 0, 0);
  background-color: rgb(200, 200, 200);
  border: 0;
  font-family: inherit;
  font-size: 2em;
  font-weight: bold;
}
@media handheld, only screen and (max-height: 640px) {
  #photo-view {
    bottom: 0;
    margin: 0;
  }
  .photo-view-container {
    display: none;
  }
  #title {
    font-size: 1em;
  }
}
